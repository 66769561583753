import { Badge, Button, Grid, LoadingBuzz, Select, Table, TextLink } from '@hexa-ui/components';

import { Check, Clock, Trash2, XCircle } from '@hexa-ui/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../api/client';
import BreadcrumbDefault from '../../components/BreadcrumbDefault';
import ButtonBack from '../../components/ButtonBack';

const fetchRequests = async (approverEmail) => {
    let baseUrl = `http://localhost:5000/workflow/list?workflowType=DummyWorkflow`;
    const { data } = await httpClient.get(baseUrl);
    return data;
}

const ResultBadge = ({ result }): JSX.Element => {

    if (result === 'failed') {
        return <Badge.Status type='default' color="red" label={result} />
    } else if (result === 'canceled') {
        return <Badge.Status type='default' color="orange" label={result} />
    } else if (result === '') {
        return <Badge.Status type='default' color="info" label={'inProgress'} />
    } else if (result === 'succeeded') {
        return <Badge.Status type='default' color="green" label={result} />
    } else {
        return <Badge.Status type='default' color="gray" label={"not started"} />
    }

}

const StatusIcon = ({ status }): JSX.Element => {
    //
    if (status == 2) {
        return <Check size='large' />;

    } else if (status == 'pending') {
        return <Clock size='large' />
    } else {
        return <XCircle size='large' style={{ color: 'red' }} />
    }
}

const WorkflowRunsPage = (): JSX.Element => {
    const navigate = useNavigate();
    const [dataRequests, setDataRequests] = useState([])
    const [searchFilter, setSearchFilter] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [filterStatus, setFilterStatus] = useState('')
    const [filterPipeline, setFilterPipeline] = useState('')
    const queryParams = new URLSearchParams(location.search);
    const pipelineId = queryParams.get('pipelineId')

    useEffect(() => {
        const data = fetchRequests('thiago.santos@ab-inbev.com');
        data.then((d) => {
            setDataRequests(d)
            setSearchFilter(d)
            setIsLoading(false)
        })

    }, [])

    const handleOnchangeFilterStatus = (e) => {
        setFilterStatus(e)
        var filteredStatus = []
        debugger
        if (filterPipeline.length > 0) {
            filteredStatus = dataRequests.filter(obj => obj["result"] === e && obj["pipelineId"] == filterPipeline);
        } else {
            filteredStatus = dataRequests.filter(obj => obj["Status"] === e);
        }

        setSearchFilter(filteredStatus);
    }

    const clearFilters = () => {
        setFilterPipeline('')
        setFilterStatus('')
        setSearchFilter(dataRequests)
    }

    return (
        <Grid.Container
            style={{
                rowGap: '1rem',
                padding: '24px 18px',
            }}
        >


            <Grid.Item lg={12}>
                <BreadcrumbDefault
                    key={1}
                    config={[

                        {
                            title: "Approval Flow",
                            href: "",
                            isCurrentPage: false
                        },
                        {
                            title: "Request Approvals",
                            href: "",
                            isCurrentPage: true
                        }

                    ]}
                />
            </Grid.Item>




            <Grid.Item lg={12} style={{ display: 'flex' }}>
                <ButtonBack link={'/oneask-portal/catalog'} />
                {/* <Header title="Request Approvals"></Header> */}

            </Grid.Item>


            {
                isLoading ? (
                    <Grid.Item xs={12} md={12} sm={12} style={{ flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                            <LoadingBuzz size='xxlarge' />
                        </div>
                    </Grid.Item>
                ) :
                    (
                        <>



                            <Grid.Container style={{ justifyContent: 'space-between' }} >
                                {/* <Grid.Item lg={6}>
                                        <Select.Root value={filterPipeline} placeholder="Pipeline" onChange={(e) => handleOnchangeFilterPipeline(e)} >
                                            <Select.Option disabled value={""}>Workflow</Select.Option>
                                        </Select.Root>
                                    </Grid.Item> */}
                                <Grid.Item lg={3}>
                                    <Select.Root value={filterStatus} placeholder="Result" onChange={(e) => handleOnchangeFilterStatus(e)} >
                                        <Select.Option disabled value={""}>Status</Select.Option>
                                        <Select.Option value={"Completed"}>Completed</Select.Option>
                                        <Select.Option value={"Failed"}>Failed</Select.Option>
                                    </Select.Root>
                                </Grid.Item>
                                <Grid.Item lg={3}>
                                    <Button icon={Trash2} leading size="large" variant="primary" onClick={() => clearFilters()}>
                                        Clear Filters
                                    </Button>
                                </Grid.Item>
                            </Grid.Container>



                            <Grid.Item lg={12} style={{ marginTop: '10px' }}>




                                <Table

                                    emptyMessage="No workflow run found"
                                    columns={[
                                        {
                                            Header: 'Workflow',
                                            accessor: 'name',
                                            disableSortBy: false,
                                            customRender: (value, row) => {
                                                return <TextLink onClick={() => { navigate(`/oneask-portal/workflow/runs/describe?workflowId=${row.workflow_id}&runId=${row.run_id}`) }}>{row.name}</TextLink>
                                                //return <Link to={`/oneask-portal/approvalflow/${1}`}  ></Link>;

                                                // const url = `/oneask-portal/approvalflow/requestDetails/${1}`
                                                // return <a href={url}></a>;

                                            },
                                            style: {
                                                width: '40%',


                                            }
                                        },
                                        {
                                            Header: 'Start Date',
                                            accessor: 'start_time',
                                            disableSortBy: false,
                                            style: {
                                                width: '20%'
                                            },
                                            customRender: (value, row) => {
                                                return row.start_time;
                                            }
                                        },
                                        {
                                            Header: 'End Date',
                                            accessor: 'close_time',
                                            disableSortBy: false,
                                            style: {
                                                width: '20%'
                                            },
                                            customRender: (value, row) => {
                                                return row.close_time;
                                            }
                                        },
                                        {
                                            Header: 'Status',
                                            accessor: 'status',
                                            disableSortBy: true,
                                            style: {
                                                width: '5%'
                                            },
                                            customRender: (value, row) => {
                                                return <StatusIcon status={row.status} />
                                            },
                                        },
                                    ]}
                                    data={searchFilter.length > 0 ? searchFilter : []}
                                    pagination={{
                                        pageSize: 10,
                                        pageSizeOptions: [
                                            5,
                                            10,
                                            20
                                        ],
                                        showPageSizeSelector: true
                                    }}
                                    initialSortBy={[

                                    ]}
                                />
                            </Grid.Item>

                        </>

                    )
            }


        </Grid.Container >
    );
};

export default WorkflowRunsPage;