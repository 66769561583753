import { Button, Card, Dialog, Grid, Heading, LoadingBuzz, Paragraph, ProgressTracker, Tabs, TextArea, Toast } from '@hexa-ui/components';

import { Check, Check2, Clock, X, XCircle } from '@hexa-ui/icons';

import { useEffect, useState } from 'react';



import { Input } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import httpClient from '../../api/client';
import BreadcrumbDefault from '../../components/BreadcrumbDefault';
import ButtonBack from '../../components/ButtonBack';



const fetchRequestDetails = async (workflowId, runId) => {
    let baseUrl = `http://localhost:5000/workflow/${workflowId}/run/describe?runId=${runId}`;
    const { data } = await httpClient.get(baseUrl);

    return data;
}

const WorkflowRunDetailsPage = (): JSX.Element => {
    const [dataRequest, setDataRequest] = useState(
        {
            status: '',
            steps: [],
            result: '',
            error_msg: '',

        }
    )
    const [searchFilter, setSearchFilter] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [filterStatus, setFilterStatus] = useState('')
    const [filterPipeline, setFilterPipeline] = useState('')
    const queryParams = new URLSearchParams(location.search);
    const { Root, Close } = Dialog;
    const [isOpenDialogReason, setIsOpenDialogReason] = useState(false);
    const [isOpenDialogApprovalToken, setIsOpenDialogApprovalToken] = useState(false);
    const { workflowId, runId } = useParams();
    const [iconStatus, setIconStatus] = useState(<Clock />);
    const [openSuccessToast, setOpenSuccessToast] = useState(false);
    const [statusRequest, setStatusRequest] = useState('pending');
    const [reasonReject, setReasonReject] = useState('');
    const [approvalToken, setApprovalToken] = useState('');
    const [errorApproval, setErrorApproval] = useState('');
    const [isApprove, setIsApprove] = useState(false);
    const [msgToastSuccess, setMsgToastSuccess] = useState('');


    useEffect(() => {
        var workflowId = queryParams.get('workflowId')
        var runId = queryParams.get('runId')
        const data = fetchRequestDetails(workflowId, runId);


        data.then((d) => {
            setDataRequest(d)
            setSearchFilter(d)
            setIsLoading(false)

            if (d.status == 'COMPLETED') {
                setIconStatus(<Check />);
            } else if (d.status == 'pending') {
                setIconStatus(<Clock />);
            } else {
                setIconStatus(<XCircle style={{ color: 'red' }} />);
            }
            setStatusRequest(d.Status)
        })

    }, [workflowId, runId])

    return (
        <Grid.Container
            style={{
                rowGap: '1rem',
                padding: '24px 18px',
            }}
        >


            <Grid.Item lg={12}>
                <BreadcrumbDefault
                    key={1}
                    config={[

                        {
                            title: "Approval Flow",
                            href: "/oneask-portal/approvalflow",
                            isCurrentPage: false
                        },
                        {
                            title: "Request Details",
                            href: "",
                            isCurrentPage: true
                        }


                    ]}
                />
            </Grid.Item>




            <Grid.Item lg={12} style={{ display: 'flex' }}>
                <ButtonBack link={'/oneask-portal/workflow/runs'} />
                {/* <Header title="Request Details"></Header> */}

            </Grid.Item>


            {
                isLoading ? (
                    <Grid.Item xs={12} md={12} sm={12} style={{ flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                            <LoadingBuzz size='xxlarge' />
                        </div>
                    </Grid.Item>
                ) :
                    (
                        <div>
                            <Card
                                elevated="medium"
                                border="medium"
                                css={{
                                    padding: '20px',
                                    width: '100%',
                                    //height: '150px',
                                    cursor: 'pointer',
                                    justifyContent: 'start',
                                    //alignItems: "center",
                                    //margin: "10px"
                                }}

                            >


                                <Grid.Container style={{ marginTop: 10 }}>
                                    <Grid.Item md={8}>
                                        <Heading size="H3" alignment="left">
                                            {dataRequest.status} {iconStatus}
                                        </Heading>
                                    </Grid.Item>

                                    {statusRequest === 'pending' && (
                                        <Grid.Item md={4}>
                                            <Grid.Item>
                                                <Button icon={X} leading size="large" variant="primary" onClick={() => setIsOpenDialogReason(true)}>
                                                    Reject
                                                </Button>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <Button icon={Check2} leading size="large" variant="primary" onClick={() => { }}>
                                                    Approve
                                                </Button>
                                            </Grid.Item>
                                        </Grid.Item>
                                    )}

                                </Grid.Container>


                                <Grid.Container style={{ marginTop: 30 }}>
                                    <Grid.Item md={8}>
                                        <strong>Descrição</strong>
                                    </Grid.Item>
                                    <Grid.Item md={4}>
                                        <strong>Requester</strong>
                                    </Grid.Item>

                                </Grid.Container>
                                <Grid.Container style={{ marginTop: 20 }}>
                                    <Grid.Item md={8}>

                                    </Grid.Item>
                                    <Grid.Item md={4}>

                                    </Grid.Item>
                                </Grid.Container>
                                {/* ----  */}
                                <Grid.Container style={{ marginTop: 20 }}>
                                    <Grid.Item md={8}>
                                        <strong>Request Date</strong>
                                    </Grid.Item>
                                    <Grid.Item md={4}>
                                        <strong>{statusRequest == 'rejected' ? 'Rejected Date' : 'Approved Date'}</strong>
                                    </Grid.Item>

                                </Grid.Container>
                                <Grid.Container style={{ marginTop: 20 }}>
                                    <Grid.Item md={8}>

                                    </Grid.Item>
                                    <Grid.Item md={4}>

                                    </Grid.Item>
                                </Grid.Container>
                                {/* ----  */}
                                <Grid.Container style={{ marginTop: 20 }}>
                                    <Grid.Item md={8}>
                                        <strong>Parameters</strong>
                                    </Grid.Item>

                                </Grid.Container>
                                <Grid.Container style={{ marginTop: 20 }}>
                                    <Grid.Item md={12}>
                                        <Tabs.Root defaultValue='tab1'>
                                            <Tabs.List>
                                                <Tabs.Trigger value='tab1'>
                                                    Input
                                                </Tabs.Trigger>
                                                <Tabs.Trigger value='tab2'>
                                                    Steps
                                                </Tabs.Trigger>
                                                <Tabs.Trigger value='tab3'>
                                                    Result
                                                </Tabs.Trigger>
                                            </Tabs.List>
                                            <Tabs.Content value='tab1'>
                                                <pre>
                                                    <code style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', display: 'block', whiteSpace: 'pre-wrap' }}>
                                                        {JSON.stringify(JSON.parse(`{
                                                            "address": "Rua Barão de Campinas .",
                                                            "age": "39",
                                                            "name": "Thiago"
                                                            }`), null, 2)}
                                                    </code>
                                                </pre>
                                            </Tabs.Content>
                                            <Tabs.Content value='tab2'>
                                                <Grid.Container>
                                                    <>
                                                        <ProgressTracker.Root
                                                            currentStep={dataRequest.steps.length}
                                                            orientation="horizontal"
                                                            size="large"
                                                        >
                                                            <ProgressTracker.Header>
                                                                {dataRequest.steps != null && dataRequest.steps.map((step, i) => {
                                                                    return (
                                                                        (step.status == 'DONE') ?
                                                                            <ProgressTracker.Step index={i} completed>
                                                                                <ProgressTracker.StepLabel
                                                                                    detail={step.status}
                                                                                    label={step.name}
                                                                                />
                                                                            </ProgressTracker.Step>
                                                                            :
                                                                            <ProgressTracker.Step index={i} error>
                                                                                <ProgressTracker.StepLabel
                                                                                    alertMessage="error to try run step"
                                                                                    detail={step.status}
                                                                                    label={step.name}
                                                                                />
                                                                            </ProgressTracker.Step>

                                                                    )
                                                                })}

                                                            </ProgressTracker.Header>
                                                        </ProgressTracker.Root>
                                                    </>

                                                </Grid.Container>
                                            </Tabs.Content>
                                            <Tabs.Content value='tab3'>
                                                {dataRequest.status == 'COMPLETED' && <pre>
                                                    <code style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', display: 'block', whiteSpace: 'pre-wrap' }}>
                                                        {JSON.stringify(JSON.parse(dataRequest.result), null, 2)}
                                                    </code>
                                                </pre>}
                                                {dataRequest.status == 'FAILED' && <pre>
                                                    <code style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', display: 'block', whiteSpace: 'pre-wrap' }}>
                                                        {dataRequest.error_msg}
                                                    </code>
                                                </pre>}

                                            </Tabs.Content>
                                        </Tabs.Root>

                                    </Grid.Item>



                                    {/* <Grid.Item md={6}>
                                    <Badge.Status label='Nome'>Nome: Tenant</Badge.Status>
                                    <strong></strong>: Tenant
                                </Grid.Item>
                                <Grid.Item md={6}>
                                    <strong>Valor</strong>: BEES
                                </Grid.Item>

                                <Grid.Item md={6}>
                                    <strong>Nome</strong>: Tenant
                                </Grid.Item>
                                <Grid.Item md={6}>
                                    <strong>Valor</strong>: BEES
                                </Grid.Item> */}

                                </Grid.Container>


                                {/* <Grid.Container style={{ marginTop: 20 }}>

                                <Grid.Item>
                                    <Button icon={X} leading size="large" variant="primary" onClick={() => setIsOpenDialogReason(true)}>
                                        Reject
                                    </Button>
                                </Grid.Item>
                                <Grid.Item>
                                    <Button icon={Check2} leading size="large" variant="primary">
                                        Approve
                                    </Button>
                                </Grid.Item>


                            </Grid.Container> */}


                                <Dialog.Root
                                    open={isOpenDialogReason}
                                    closeButton={false}
                                    actions={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Dialog.Close>
                                            <Button onClick={() => { setIsOpenDialogReason(false) }} size="medium" style={{ marginRight: '0.9375rem' }} variant="secondary">Cancel</Button>
                                        </Dialog.Close>
                                        <Button size="medium" variant="primary" onClick={() => setIsOpenDialogApprovalToken(true)} >Confirm</Button>
                                    </div>}
                                    title={<Heading>Reason Reject</Heading>}
                                    trigger={isOpenDialogReason}


                                >

                                    <Paragraph>
                                        <TextArea style={{ width: '500px' }} placeholder='Why are you rejecting this request?' onChange={(e) => { }} ></TextArea>
                                    </Paragraph>

                                </Dialog.Root>

                                <Dialog.Root
                                    open={isOpenDialogApprovalToken}
                                    closeButton={false}
                                    actions={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Dialog.Close>
                                            <Button onClick={() => { setIsOpenDialogApprovalToken(false); setErrorApproval('') }} size="medium" style={{ marginRight: '0.9375rem' }} variant="secondary">Cancel</Button>
                                        </Dialog.Close>
                                        <Button size="medium" variant="primary" onClick={() => {
                                            // if (isApprove) {

                                            //     approveRequest(dataRequest, id)
                                            // } else {

                                            //     rejectRequest(dataRequest, id, reasonReject)
                                            // }
                                        }} >Confirm</Button>
                                    </div>}
                                    title={<Heading>Approval Token</Heading>}
                                    trigger={isOpenDialogApprovalToken}


                                >
                                    <Paragraph>
                                        <Input type={'password'} style={{ width: '500px', marginTop: 20 }} placeholder='Paste the token you received by email here' onChange={(e) => setApprovalToken(e.currentTarget.value)}></Input>
                                    </Paragraph>
                                    <Paragraph>
                                        <span style={{ color: 'red' }}>{errorApproval}</span>
                                    </Paragraph>

                                </Dialog.Root>

                            </Card>
                            {statusRequest == 'rejected' &&
                                <Card
                                    elevated="medium"
                                    border="medium"
                                    css={{
                                        padding: '20px',
                                        width: '100%',
                                        //height: '150px',
                                        cursor: 'pointer',
                                        justifyContent: 'start',
                                        //alignItems: "center",
                                        //margin: "10px"
                                        marginTop: 10
                                    }}

                                >
                                    <Grid.Container style={{}}>
                                        <Grid.Item md={12}>
                                            <Heading size="H3" alignment="left">
                                                Reason Rejected
                                            </Heading>
                                        </Grid.Item>
                                        <Grid.Item md={12}>
                                            <Paragraph>{dataRequest}</Paragraph>
                                        </Grid.Item>



                                    </Grid.Container>
                                </Card>
                            }


                        </div>

                    )
            }
            <Toast.Provider
                duration={5000}
                swipeDirection="right"
            >
                <Toast.Root
                    open={openSuccessToast}
                    onOpenChange={setOpenSuccessToast}
                    message={msgToastSuccess}
                    showCloseButton
                    position="top-right"
                    type="success"
                />

            </Toast.Provider>

        </Grid.Container >
    );
};

export default WorkflowRunDetailsPage;