import { IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import { Link } from 'react-router-dom';

const ButtonBack = ({ link }) => {

    return (
        <Link to={link}>
            <IconButton
                icon={ArrowLeft}
                style={{ marginRight: '20px' }}
            />
        </Link>
    );
};

export default ButtonBack;

